var sw = sw || {};

// Time counter
//-----------------------------------------------------------------
sw.inview = function() {

    // $('.bg-graphic').each(function(i) {
    //     $(this).addClass("animate");
    // });
    

    $('.animate').one('inview', function(event, isInView) {
        if (isInView) {
            $(this).addClass('animated');
        } else {
            // $(this).removeClass('animated');
        }
    });

};


$(document).ready(function (){
    sw.inview();
});
