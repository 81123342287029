var sw = sw || {};

sw.click_touchstart = (function(){
    return Modernizr.touchEvents ? 'touchstart' : 'click';
})();


// When ready do stuff
//----------------------------------------------------------
$(document).ready(function(){

    // $('html').addClass('dom-ready');

    //Check if browser supports SVG. If not, display .png 
    // if (!Modernizr.svg) {
    //     $("img[src$='.svg']").attr("src", fallback);
    // }


    // Checkbox & Radio buttons replacement
    // $('.styled[type=checkbox], .styled[type=radio]').zkRadioCheck();

    
});


